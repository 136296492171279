import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css'
import {Button, Nav, Navbar} from 'react-bootstrap'
import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom';
import Home from "./components/home";
import Indicator from "./components/indicator";
import Goal from "./components/goal";


function App() {



    return (
        <div className="App">
            <Router>
                <header className={"mb-3"}>
                    <Navbar bg="dark" variant="dark">
                        <Navbar.Brand href="#home"> <img src="/logo.png" height="50"
                                                         className="d-inline-block align-top" alt=""/></Navbar.Brand>
                        <Nav className="mr-auto">
                            <Nav.Link href="/">Home</Nav.Link>
                        </Nav>
                    </Navbar>
                </header>
                <main>
                    <div className={"container-fluid"}>
                        <Switch>
                            <Route exact path='/' component={Home} />
                            <Route exact path='/goal/:id' component={Goal} />
                            <Route path={'/indicator/:id'} component={Indicator}/>
                        </Switch>
                    </div>
                </main>
            </Router>
        </div>
    );
}

export default App;
