import axios from "../custom-axios/axios";

const IndicatorRepository = {

    fetchIndicatorsByGoal: (goal) => {
        return axios.get(`/goal/${goal}/indicators`);
    },

    fetchIndicatorInfo: (ind) => {
        return axios.get(`/indicator/${ind}/info`);
    },

    fetchRelatedIndicator: (ind)=> {
        return axios.get(`/indicator/${ind}/related`);
    },

    fetchEquation: (ind) => {
        return axios.get(`/indicator/${ind}/related/equation`)
    }
}

export default IndicatorRepository;