import {useEffect, useState} from "react";
import axios from 'axios';
import IndicatorRepository from "../repository/indicatorRepository";
import Latex from 'react-latex'
import {Badge} from "react-bootstrap";

function Indicator(props) {

    const [indicator, setIndicator] = useState({})
    const [related, setRelated] = useState([]);
    const [equation, setEquation] = useState({})
    const [equations, setEquations] = useState([])

    useEffect(() => {
        IndicatorRepository.fetchIndicatorInfo(props.match.params.id).then((data) => {
            setIndicator(data.data[0]);
        })
        IndicatorRepository.fetchRelatedIndicator(props.match.params.id).then((data) => {
            setRelated(data.data);
        })
        IndicatorRepository.fetchEquation(props.match.params.id).then((data) => {
            debugger;
            setEquations(data.data['eq'])
        })
    }, []);

    return (
        <>

            <div className={"row"}>
                <div className={"col-md-7"}>
                    <h5 className={"heading text-left"}>Indicator Basic Info</h5>
                    <hr/>
                    <div className={"table table-responsive"}>
                        <table className="table table-xs table-striped table-bordered  table-primary">
                            <tbody>
                            <tr>
                                <td align={"right"}><b>Indicator</b></td>
                                <td align={"left"}>{indicator.indicator}</td>
                                <td align={"right"}><b>Indicator Name</b></td>
                                <td align={"left"}>{indicator.name}</td>
                            </tr>
                            <tr>
                                <td colSpan={"2"} align={"right"}><b>Definition</b></td>
                                <td colSpan={"2"} align={"left"}>{indicator.definition}</td>
                            </tr>
                            <tr>
                                <td colSpan={"2"} align={"right"}><b>Related Indicators</b></td>
                                <td colSpan={"2"} align={"left"}>{indicator.related}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <h5 className={"heading text-left"}>Top 5 Related Indicators</h5>
                    <hr/>
                    <div className={"table table-responsive"}>
                        <table className="table table-xs table-striped table-bordered  table-danger">
                            <thead>
                            <tr>
                                <th scope="col">Rank</th>
                                <th scope="col">Indicator</th>
                                <th scope="col">Definition</th>
                            </tr>
                            </thead>
                            <tbody>
                            {related.slice(0, 5).map((i, index) =>
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{i.indicator}</td>
                                    <td>{i.name}</td>
                                </tr>
                            )
                            }
                            </tbody>
                        </table>
                    </div>
                    <h5 className={"heading text-left"}>Other Related Indicators</h5>
                    <hr/>
                    <div className={"text-left"}>
                        {related.slice(5, 15).map((i, index) =>
                            <Badge className={"badge-pill "} style={{}} title={i.name}  style={{"margin-left":"10px", "font-size":"1em"}} variant="primary">{i.indicator}</Badge>
                        )
                        }
                    </div>


                </div>
                <div className={"col-md-5"}>
                    <h5 className={"heading text-left"}>SHAP Values</h5>
                    <hr/>
                    <div>
                        <img className={"img-fluid"} width={"100%"} src={`/images/shap/${props.match.params.id}.png`}/>
                    </div>
                    <hr/>


                </div>

            </div>

            <div className={"row"}>
                <div className={"col-md-12"}>
                    <h5 className={"heading text-left mt-5"}>Mathematical Relatedness</h5>
                    <hr/>
                    <div style={{"width":"100%"}}>
                        <table className="table table-xs table-striped table-bordered  table-danger">
                            <thead>
                            <tr>
                                <th scope="col">Rank</th>
                                <th scope="col">Indicator</th>
                                <th scope="col">Number of variables</th>
                                <th scope="col">Adj R2 score</th>
                                <th scope="col">Degree</th>
                                <th scope="col" align={"left"}>Equation</th>
                            </tr>
                            </thead>
                            <tbody>
                            {equations.map((i, index) =>
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{i.ind}</td>
                                    <td>{i.N}</td>
                                    <td>{i.adj_r2}</td>
                                    <td>{i.degree}</td>
                                    <td align={"left"} ><Latex fleqn={true} maxSize={"50px"} displayMode={"true"}>{"$" + i.eq + "$"}</Latex></td>
                                </tr>
                            )
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className={"row"}>
                {/*{related.map((i) =>*/}
                {/*    <div key={i} className={"col-md-2"}>*/}
                {/*        <label>{i}</label>*/}
                {/*    </div>*/}
                {/*)*/}
                {/*}*/}
            </div>
        </>
    )
}

export default Indicator;