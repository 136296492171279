import axios from "axios";


const instance = axios.create({
    baseURL: 'http://sdg-backend.ireason.mk/'

})



export default instance;