const Home = (props) => {

    const sdgs = [
        {
            "id": 1,
            "name": "Goal 1",
            "icon": "E-WEB-Goal-01.png"
        },
        {
            "id": 2,
            "name": "Goal 2",
            "icon": "E-WEB-Goal-02.png"
        },
        {
            "id": 3,
            "name": "Goal 3",
            "icon": "E-WEB-Goal-03.png"
        },
        {
            "id": 4,
            "name": "Goal 4",
            "icon": "E-WEB-Goal-04.png"
        },
        {
            "id": 5,
            "name": "Goal 5",
            "icon": "E-WEB-Goal-05.png"
        },
        {
            "id": 6,
            "name": "Goal 6",
            "icon": "E-WEB-Goal-06.png"
        },
        {
            "id": 7,
            "name": "Goal 7",
            "icon": "E-WEB-Goal-07.png"
        },
        {
            "id": 8,
            "name": "Goal 8",
            "icon": "E-WEB-Goal-08.png"
        },
        {
            "id": 9,
            "name": "Goal 9",
            "icon": "E-WEB-Goal-09.png"
        },
        {
            "id": 10,
            "name": "Goal 10",
            "icon": "E-WEB-Goal-10.png"
        },
        {
            "id": 11,
            "name": "Goal 11",
            "icon": "E-WEB-Goal-11.png"
        },
        {
            "id": 12,
            "name": "Goal 12",
            "icon": "E-WEB-Goal-12.png"
        },
        {
            "id": 13,
            "name": "Goal 13",
            "icon": "E-WEB-Goal-13.png"
        },
        {
            "id": 14,
            "name": "Goal 14",
            "icon": "E-WEB-Goal-14.png"
        },
        {
            "id": 15,
            "name": "Goal 15",
            "icon": "E-WEB-Goal-15.png"
        },
        {
            "id": 16,
            "name": "Goal 16",
            "icon": "E-WEB-Goal-16.png"
        },
        {
            "id": 17,
            "name": "Goal 17",
            "icon": "E-WEB-Goal-17.png"
        },
    ];

    return (
        <div className={"row"}>
            {sdgs.map((i) =>
                <div key={i.name} className={"col-md-2"}>
                    <a href={"/goal/" + i.id}>
                        <img className={"img-fluid mb-4"} src={"/images/" + i.icon}/>
                    </a>
                </div>
            )
            }
        </div>
    );
}

export default Home;