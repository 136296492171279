import {useEffect,useState} from "react";
import axios from 'axios';
import IndicatorRepository from "../repository/indicatorRepository";

function Goal(props) {

    const [indicators, setIndicators] = useState([]);

    useEffect(() => {
        IndicatorRepository.fetchIndicatorsByGoal(props.match.params.id).then((data)=>{
            setIndicators(data.data)
        })
    },[]);

    return (
        <div className={"table table-responsive"}>
            <table className="table table-striped">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Indicator</th>
                    <th scope="col">Name</th>
                    <th scope="col">Definition</th>
                </tr>
                </thead>
                <tbody>
                {indicators.map((i) =>
                    <tr key={i.id}>
                        <th scope="row"></th>
                        <td><a href={`/indicator/${i.indicator}`}>{i.indicator}</a></td>
                        <td>{i.name}</td>
                        <td>{i.definition}</td>
                    </tr>
                )
                }
                </tbody>
            </table>

        </div>
    )
}

export default Goal;